{
  "name": "dependency_guard",
  "version": "0.1.0",
  "type": "module",
  "author": {
    "name": "Chritch",
    "url": "http://github.com/Zerokei",
    "email": "chritch-7@outlook.com"
  },
  "license": "GPL-3.0",
  "browserslist": [
    "last 2 versions"
  ],
  "funding": [],
  "dependencies": {
    "@hpcc-js/wasm": "1.14.1",
    "@types/node": "^20.3.1",
    "axios": "^1.4.0",
    "chart.js": "^4.3.0",
    "cheerio": "^1.0.0-rc.12",
    "d3-hierarchy": "3.1.2",
    "d3-interpolate": "3.0.1",
    "d3-scale": "4.0.2",
    "d3-scale-chromatic": "3.0.0",
    "d3-selection": "3.0.0",
    "d3-shape": "3.1.0",
    "express": "^4.18.2",
    "filter-altered-clicks": "1.0.1",
    "md5": "2.3.0",
    "react": "18.0.0",
    "react-chartjs-2": "^5.2.0",
    "react-dom": "18.0.0",
    "semver": "7.3.7"
  },
  "description": "Visualize npm dependency graphs (public and private!)",
  "keywords": [
    "npm",
    "dependencies",
    "dependency",
    "graph",
    "module"
  ],
  "repository": {
    "url": "https://github.com/Zerokei/DepGuard.git",
    "type": "git"
  },
  "devDependencies": {
    "@parcel/runtime-react-refresh": "^2.7.0",
    "@parcel/transformer-sass": "^2.7.0",
    "@types/md5": "2.3.2",
    "@types/semver": "^7.5.0",
    "@typescript-eslint/eslint-plugin": "5.19.0",
    "@typescript-eslint/parser": "5.19.0",
    "assert": "^2.0.0",
    "browserify-zlib": "^0.2.0",
    "buffer": "^6.0.3",
    "crypto-browserify": "^3.12.0",
    "eslint": "8.13.0",
    "eslint-config-prettier": "8.5.0",
    "eslint-plugin-prettier": "4.0.0",
    "events": "^3.3.0",
    "parcel": "^2.7.0",
    "path-browserify": "^1.0.1",
    "prettier": "2.6.2",
    "process": "0.11.10",
    "querystring-es3": "^0.2.1",
    "standard-version": "9.3.2",
    "stream-browserify": "^3.0.0",
    "stream-http": "^3.2.0",
    "typescript": "4.6.3",
    "url": "^0.11.1",
    "util": "^0.12.5"
  },
  "scripts": {
    "build": "parcel build index.html",
    "release": "standard-version",
    "start": "parcel serve index.html",
    "test": "eslint . && prettier -c .",
    "fix": "prettier -w ."
  }
}
